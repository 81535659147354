import PrintIcon from "@material-ui/icons/Print";
import { CircularProgress } from "@material-ui/core";
import React, { ReactElement } from "react";
import { Button, useQueryWithStore } from "react-admin";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import QRCode from "qrcode.react";
import {
  convertLastWidthCategoryToSteitzWidth,
  displayUserIdentifier,
} from "../../../utils/Helper";

const styles = StyleSheet.create({
  wrapper: { display: "flex", flexWrap: "wrap", flexDirection: "row" },
  margin: { height: "13.1mm" },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    height: "67.7mm",
    width: "70mm",
    padding: "4 0 10 0",
  },
  text: { fontSize: 8, padding: "6 0 6 0" },
  image: { objectFit: "contain" },
});

type BulkPrintQRProps = {
  selectedIds: number[];
  resource: string;
};
export const BulkPrintQR = (props: BulkPrintQRProps): ReactElement => {
  const { selectedIds, resource } = props;
  const { data } = useQueryWithStore({
    type: "getMany",
    resource,
    payload: { ids: selectedIds },
  });

  const renderDocumentData = () => {
    const documentData: Array<any> = [];
    let counter = 0;
    if (data) {
      Object.values(data).map((item: any) => {
        const {
          id,
          industry_partner: industryPartner,
          site,
          brand,
          variant,
          eu,
          last_width_category: lastWidthCategory,
          olid,
        } = item;

        const qrCodeCanvas = document.querySelector(`#element${id}`);
        const qrCode = qrCodeCanvas && qrCodeCanvas.toDataURL("image/png", 1);

        counter += 1;
        // counter > 2 to make sure that it doesn't add the element on count 1
        // (counter - 1) % 12 === 0 to check if counter is bigger than a number divisible by 12. eg. counter = 13 or counter = 25
        const shouldInsertBreak = counter > 2 && (counter - 1) % 12 === 0;
        documentData.push(
          <>
            {shouldInsertBreak && <View break style={styles.margin} />}
            <View style={styles.box} key={id}>
              <Image src={qrCode} style={styles.image} />
              <Text style={styles.text}>{`Partner: ${industryPartner}`}</Text>
              <Text style={styles.text}>{`Site: ${site}`}</Text>
              <Text style={styles.text}>{`Customer: ${displayUserIdentifier(
                item
              )}`}</Text>
              <Text style={styles.text}>{`Olid: ${olid}`}</Text>
              <Text {...(shouldInsertBreak ? { style: { padding: 4 } } : {})} />
              <Text
                style={[styles.text, { fontSize: shouldInsertBreak ? 8 : 9 }]}
              >{`${brand} ${variant}`}</Text>
              <Text
                style={styles.text}
              >{`Left Size: ${eu}, Right Size ${eu}`}</Text>
              <Text
                style={styles.text}
              >{`Left Width: ${convertLastWidthCategoryToSteitzWidth(
                lastWidthCategory
              )}, Right Width ${convertLastWidthCategoryToSteitzWidth(
                lastWidthCategory
              )}`}</Text>
            </View>
          </>
        );

        return null;
      });
    }

    return documentData;
  };

  const MyDocument = () => {
    return (
      <Document>
        <Page size="A4" wrap>
          <View fixed style={styles.margin} />
          <View style={styles.wrapper}>{renderDocumentData()}</View>
        </Page>
      </Document>
    );
  };

  return (
    <>
      {data &&
        data.map((element: any) => {
          const { id, sha1, v, t, oid, olid } = element;
          const stringifiedJson = JSON.stringify(
            { sha1, v, t, oid, olid },
            null,
            2
          );

          return (
            <div style={{ display: "none" }}>
              <QRCode
                value={stringifiedJson}
                data-qr={id}
                size={125}
                id={`element${id}`}
              />
            </div>
          );
        })}
      <PDFDownloadLink document={<MyDocument />} fileName="qr_codes.pdf">
        {({ blob }) =>
          blob && blob.size > 1174 ? (
            <Button label="Print Qr Codes" size="medium">
              <PrintIcon />
            </Button>
          ) : (
            <CircularProgress size={30} color="inherit" />
          )
        }
      </PDFDownloadLink>
    </>
  );
};
